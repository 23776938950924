var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "leaveModal",
    attrs: {
      id: "leaveModal",
      "no-close-on-backdrop": "",
      "footer-class": "flex-nowrap",
      centered: ""
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function() {
          return [_c("h3", [_vm._v(_vm._s(_vm.$t("modals.leave.title")))])]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "p",
              { staticClass: "mt-5 mb-4 text-center" },
              [
                _c("b-icon", {
                  staticStyle: {
                    width: "60px",
                    height: "60px",
                    color: "#53dc86"
                  },
                  attrs: { icon: "exclamation-circle" }
                })
              ],
              1
            ),
            _c("p", {
              staticClass: "mb-5 text-center",
              domProps: { innerHTML: _vm._s(_vm.$t("modals.leave.content")) }
            })
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function({ close, ok }) {
          return [
            _c(
              "b-button",
              {
                staticClass: "p-3 border-right-light-grey",
                attrs: { block: "" },
                on: {
                  click: function($event) {
                    return close()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
            ),
            _c(
              "b-button",
              {
                staticClass: "p-3",
                attrs: { variant: "primary", block: "" },
                on: {
                  click: function($event) {
                    return ok()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }