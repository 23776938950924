<template>
  <b-modal
    ref="leaveModal"
    id="leaveModal"
    no-close-on-backdrop
    footer-class="flex-nowrap"
    centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.leave.title') }}</h3>
    </template>
    <template #default>
      <p class="mt-5 mb-4 text-center">
        <b-icon
          icon="exclamation-circle"
          style="width: 60px; height: 60px; color: #53dc86"
        ></b-icon>
      </p>
      <p class="mb-5 text-center" v-html="$t('modals.leave.content')"></p>
    </template>
    <template #modal-footer="{ close, ok }">
      <b-button
        class="p-3 border-right-light-grey"
        block
        @click="close()"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        class="p-3"
        block
        @click="ok()"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalLeavePage',
};
</script>
